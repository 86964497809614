<template>
  <div>
    <b-modal
      id="edit-employee-store"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditToStore')"
      hide-footer
      @close="onReset"
    >
      <b-form>
        <b-form-group :label="`${$t('Stores')}:`">
          <vue-select
            id="input-5"
            v-model="form.storeId"
            :options="getStoreNameByLeadership"
            :reduce="(e) => e.storeId"
            label="storeName"
            :placeholder="$t('SelectStore')"
          />
        </b-form-group>
        <div class="buttonsEverywhere">
          <button
            type="button"
            class="buttonSubmit"
            variant="none"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"

            style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onReset"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
// import { required, minLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  props: ['userId', 'editEmployeeStoreDetails'],
  data() {
    return {
      form: {
        storeId: null,
      },
    }
  },
  computed: {
    ...mapGetters(['getStoreNameByLeadership']),
  },
  watch: {
    editEmployeeStoreDetails(value) {
      this.form.storeId = value.storeId
    },
  },
  mounted() {
    this.getStoreNames()
  },
  methods: {
    ...mapActions([
      'getStoreNames',
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const form = {
        storeId: this.form.storeId,
        userId: this.userId,
      };
      this.$emit('editEmpStore', form);
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.storeId = null
      this.userId = ''
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
};
</script>

  <style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .flex-form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 10px;
  }
  </style>
